#ItemInterface-container {
	width: 100%;
	padding: 10px 15px;
}

#ItemInterface-container > * {
	margin: 25px auto;
	text-align: center;
}

#ItemInterface-container > span:first-child {
	display: block;
	font-size: 0.8rem;
	font-weight: bold;
	color: var(--bs-gray-500);
}

#ItemInterface-container > img {
	display: block;
	width: 100px;
	height: 100px;
}

#ItemInterface-container > span:nth-child(3) {
	display: block;
	font-size: 1.2rem;
	font-weight: bold;
}

#ItemInterface-container > span:nth-child(4) {
	display: block;
	font-size: 0.9rem;
}

