#Nav-container {
  display: grid;
  grid-template-columns: 1fr;
}

#Logo-box {
  position: relative;
  min-height: 60px;
}
#Logo-box > * > img {
  width: 46px;
  height: 43px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#Nav-box {
  position: absolute;
  width: 100vw;
  padding-left: 0;
  z-index: 1;
  top: 60px;
  left: 0;
  background-color: var(--bs-black);
  transition: display 0.5s;
  -webkit-transition: display 0.5s;
}
#Nav-ul {
  display: none;
  list-style-type: none;
}

.Li-items {
  font-family: 'Tektur', cursive;
  padding: 2vh 5vw;
  font-size: 1.5rem;
  letter-spacing: 1px;
  color: var(--bs-gray-500);
  cursor: pointer;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.Li-items:hover {
  border-left: 5px solid var(--bs-indigo);
  color: var(--bs-gray-100);
}

#Hamburger-container {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}
#Hamburger-container:after {
  content: '\A';
  position: absolute;
  width: 100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
#Hamburger-container:hover:after {
  opacity: 1;
}
#Hamburger-container > img {
  width: 100%;
  vertical-align: top;
}

@media (min-width: 700px) {
  #Nav-container {
		display: grid;
		grid-template-columns: 50px auto;
  }
  #Nav-box {
    position: static;
    align-self: center;
    width: auto;
    
  }
  #Nav-ul {
    display: contents !important;
  }
  .Li-items {
		float: left;
		font-family: 'Tektur', cursive;
		padding: 0 1.5vw;
		font-size: 1rem;
		letter-spacing: 1px;
		color: var(--bs-gray-500);
		cursor: pointer;
		border-bottom: 2px solid transparent;
		transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .Li-items:hover {
	border-left: 0;
	border-bottom: 2px solid var(--bs-indigo);
	color: var(--bs-gray-100);
  }

  #Hamburger-container {
	display: none;
  }
  
}

