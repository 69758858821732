.Slide-box {
	width: 100%;
	position: relative;
	margin: 20px auto;
	text-align: center;

}

.Back {
  display: none;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-0, -50%);
	-ms-transform: translate(-0, -50%);
}

.Next {
  display: none;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(-0, -50%);
	-ms-transform: translate(-0, -50%);
}

.Dir-button {
  background-color: transparent !important;
  border: none !important;
  font-size: 80px !important;
  -webkit-text-fill-color: black;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 1.00px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.Dir-button:hover {
  font-size: 100px !important;
}

@media (min-width: 700px) {
  .Slide-box {
    width: 90%;
    max-width: 700px;
  }
  .Back, .Next {
    display: initial;
  }
}