#Privacy-h1 {
  display: block;
  position: relative;
  margin: 100px 5% 50px;
  text-align: center;
}

#Privacy-img {
  display: block;
  width: 50vw;
  aspect-ratio: 1/1;
  margin: 50px auto;
}
