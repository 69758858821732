#ExplanationHowTo-container {
	position: relative;
	width: 90%;
	margin: 10px auto;
	text-align: center;
}

#ExplanationHowTo-container > h1 {
  display: block;
  margin: 50px auto;
	font-size: 2rem;
	font-family: 'Tektur', cursive;
	letter-spacing: 1px;
}

#Flex-box {
	display: grid;
	grid-template-columns: 1fr;
}
#Flex-box > * {
	margin: 20px auto;
}

@media (min-width: 700px) {
	#Flex-box {
		grid-template-columns: 1fr 1fr;
	}
}