#FooterContent-container {
	display: grid;
	grid-template-columns: 1fr;
	margin: 50px 5%;
}
#FooterContent-container > * {
	align-items: center;
	text-align: center;
	margin: 20px 0;
	padding: 10px 0;
}
#Footer-logo {
	width: 50px;
	height: 50px;
}
.Footer-headers {
	font-size: 1.2rem;
}
.Footer-links {
	margin: 9px 0;
	font-size: 0.9rem;
}
.Footer-links > * {
	white-space: nowrap;
}

#Copyright {
	display: block;
	padding: 5px 5px 20px;
	text-align: center;
	font-size: 0.65rem;
	font-weight: 400;
}

@media (min-width: 700px) {
	#FooterContent-container {
		grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
		margin: 150px 0 100px;
	}

	#FooterContent-container > div:first-child {
		width: 100%;
	}

	#Footer-logo {
		vertical-align: center;
	}

	.Footer-headers, .Footer-links {
		text-align: left;
	}
}